.telus-input-field {
  font-family: $font-family-sans-serif;
  font-size: 14px;
  line-height: 24px;
  padding: 0 12px;
  height: 44px;
  position: relative;
  top: 0;
  margin: 2px 0;
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-appearance: none;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
}

.telus-input-field:disabled {
  pointer-events: none;
}

.telus-input-field.color-light {
  background-color: white;
  color: $gray-66;
  border-color: lighten($gray-66, 30%);
}

.telus-input-field.color-light {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.telus-input-field.color-light {
  background-color: white;
  color: $gray-66;
  border-color: lighten($gray-66, 30%);
}

.telus-input-field.color-light:hover {
  border: 1px solid $green-66;
  -webkit-box-shadow: 0 0 1px $green-66;
  -moz-box-shadow: 0 0 1px $green-66;
  box-shadow: 0 0 1px $green-66;
}

.telus-input-field.color-light:focus {
  border: 1px solid $purple-49;
  -webkit-box-shadow: 0 0 1px $purple-49;
  -moz-box-shadow: 0 0 1px $purple-49;
  box-shadow: 0 0 1px $purple-49;
}

.telus-input-field:disabled {
  pointer-events: none;
}

/**
 * Input groups
 *
 */
div.input-group {
  .input-note {
    @media #{$medium-up} {
        font-size: rem-calc(14);
        margin-top: 10px;
    }
    @media #{$small-only} {
        font-size: rem-calc(15);
        margin-top: 5px;
    }
    display: inline-block;
    line-height: 1.3;
    color: $gray-66;
  }
  label.error-text,p.error-text {
    display: none;
    width: 100%;
  }
  input {
    display: block;
    width: 100%;
  }
  div.input-error-icon,
  div.input-success-icon {
    display: none;
    font-size: 1.2em;
    position: absolute;
    top: 0.7em;
    right: 0.6em;
    height: 1em;
    width: 1em;
    line-height: 1;
  }
}
.telus-select-container.input-group {
  div.telus-icon {
    top: 0.7em;
    right: 1.6em;
  }
}
div.input-group.input-error {
  position: relative;
  input.telus-input-field {
    border-color: $red-c8;
    width: 100%;
  }
  label.error-text,p.error-text {
    display: block !important;
    color: $gray-66;
    font-size: 14px;
    font-weight: light;
    padding: 5px 8px;
    margin-top: 10px;
    background-color: lighten($red-c8, 52%);
  }
  label.error-text:before,
  p.error-text:before {
    content: 'o';
    font-family: 'telus';
    position: absolute;
    top: 44px;
    left: 10%;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
    color: lighten($red-c8, 52%);
  }
  div.telus-icon.input-error-icon {
    display: block !important;
    color: $red-c8;
  }
}
div.input-group.input-success {
  position: relative;
  div.telus-icon.input-success-icon {
    display: block !important;
    color: $green-66;
  }
}
