.telus-checkbox.regular {
  padding-bottom: 10px;
  font-size: 17px;
}
.telus-checkbox.regular:last-child {
  padding-bottom: 0;
}
.telus-checkbox.regular > .inner {
  position: relative;
}
.telus-checkbox.regular input[type="checkbox"],
.telus-checkbox.regular input[type="radio"],
.telus-checkbox.regular .icon {
  position: absolute;
  top: 50%;
  left: 0;
  width: 31px;
  height: 31px;
  line-height: 31px;
  margin-top: -15px;
  text-align: center;
  border: 2px solid #cccccc;
  background-color: white;
}
.telus-checkbox.regular .icon {
  color: white;
}
.telus-checkbox.regular input[type="checkbox"],
.telus-checkbox.regular input[type="radio"] {
  opacity: 0;
  z-index: 1000;
}
.telus-checkbox.regular input[type="checkbox"]:focus ~ .icon,
.telus-checkbox.regular input[type="radio"]:focus ~ .icon {
  outline: none;
  border-color: #57a708;
}
.telus-checkbox.regular input[type="checkbox"]:checked ~ .icon,
.telus-checkbox.regular input[type="radio"]:checked ~ .icon {
  color: #66cc00;
  outline: none;
}

.telus-checkbox.regular input[type="checkbox"] ~ .icon .icon-checkmark {
  display: none;
}

.telus-checkbox.regular input[type="checkbox"]:checked ~ .icon .icon-checkmark {
  display: inline;
}

.telus-checkbox.regular input[type="radio"] ~ .icon {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  border: 2px solid $gray-ae;
}
.telus-checkbox.regular input[type="radio"]:checked ~ .icon {
  background-color: $green-66;
  -webkit-box-shadow: inset 0 0 0 5px hsl(0, 0%, 100%);
  -moz-box-shadow: inset 0 0 0 5px hsl(0, 0%, 100%);
  box-shadow: inset 0 0 0 5px hsl(0, 0%, 100%);
}
.telus-checkbox.regular input[type="radio"]:checked:focus ~ .icon {
  border: 2px solid #57a708;
}
.telus-checkbox.regular label {
  display: block;
  padding: 10px 0 10px 45px;
  text-align: left;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
  cursor: pointer;
}
.telus-checkbox.regular label.small {
  font-size: 14px;
  line-height: 20px;
}
.telus-checkbox.regular.left-label input[type="checkbox"],
.telus-checkbox.regular.left-label input[type="radio"],
.telus-checkbox.regular.left-label .icon {
  left: auto;
  right: 0;
}
.telus-checkbox.regular.left-label label {
  padding-left: 0;
  padding-right: 45px;
}
.telus-checkbox.regular.small {
  padding-bottom: 0px;
}
.telus-checkbox.regular.small input[type="checkbox"],
.telus-checkbox.regular.small input[type="radio"],
.telus-checkbox.regular.small .icon {
  width: 20px;
  height: 20px;
  line-height: 12px;
  margin-top: -10px;
}
.telus-checkbox.regular.small .icon .telus-select-container:after {
  font-size: 12px;
}
.telus-checkbox.regular.small .icon .telus-icon {
  font-size: 15px;
}
.telus-checkbox.regular.small input[type="radio"]:checked ~ .icon {
  background-size: 20px 20px;
  background-repeat: no-repeat;
}
.telus-checkbox.regular.small input[type="radio"]:checked:focus ~ .icon {
  background-size: 18px 18px;
}
.telus-checkbox.regular.small label {
  padding: 9px 0 9px 35px;
}
.telus-checkbox.regular.small.left-label label {
  padding-right: 35px;
}