$telus-panel-side-padding-lg: 30px;
$telus-panel-side-padding-md: 25px;
$telus-panel-side-padding-sm: 20px;


div.panel {
    color: $gray-66;
    margin-top: 0px;
    margin-bottom: 10px;

}
div.panel.telus-panel {
    outline: 0;
    border: none;
    -webkit-box-shadow: 0 0 5px 3px rgba(206,206,206,0.40);
    -moz-box-shadow: 0 0 5px 3px rgba(206,206,206,0.40);
    box-shadow: 0 0 5px 3px rgba(206,206,206,0.40);
    font-weight: $font-weight-light;
    .panel-header {
        margin: 0px;
        @media #{$large-up} {
            padding: 15px $telus-panel-side-padding-lg;
        }
        @media #{$medium-only} {
            padding: 13px $telus-panel-side-padding-md;
        }
        @media #{$small-only} {
            padding: 10px $telus-panel-side-padding-sm;
        }
        * {
            margin: 0px;
            color: $gray-66;
            font-weight: $font-weight-light;
        }
    }
    .panel-body {
        @media #{$large-up} {
            padding: 0px $telus-panel-side-padding-lg 25px $telus-panel-side-padding-lg;
        }
        @media #{$medium-only} {
            padding: 0px $telus-panel-side-padding-md 25px $telus-panel-side-padding-md;
        }
        @media #{$small-only} {
            padding: 0px $telus-panel-side-padding-sm 25px $telus-panel-side-padding-sm;
        }
        font-weight: $font-weight-light;
        strong {
            font-weight: $font-weight-bold;
        }
        *.row {
            margin: 0px;
            span {
                padding-bottom: 5px;
            }
        }
        .row:last-of-type {
            padding-bottom: 25px;
        }
    }
    .panel-footer {
        @media #{$large-up} {
            padding: 12px $telus-panel-side-padding-lg;
        }
        @media #{$medium-only} {
            padding: 10px $telus-panel-side-padding-md;
        }
        @media #{$small-only} {
            padding: 9px $telus-panel-side-padding-sm;
        }
        border-top: 1px solid rgba(206,206,206,0.60);
        a {
            margin-bottom: 0px;
        }
    }
    a {
        vertical-align: middle;
        display: inline-block;
        margin-bottom: 10px;
        font-weight: $font-weight-normal;
        .telus-icon {
            display: inline-block;
            font-size: 16px;
            margin-left: 0.3em;
            vertical-align: bottom;
            color: inherit;
        }
    }
    a:hover {
        text-decoration: underline;
        color: $purple-8c;
    }
}


div.input-panel.panel {
    padding: 20px;
    background-color: $gray-f5;
    color: $gray-66;
    margin-top: 0px;
    margin-bottom: 10px;
    border: none;
    .panel-header {
        margin: 0px 0px 15px 0px;
        * {
            margin: 0px;
        }
    }
}
.input-panel {

    .panel-body {
        label {
            display: block;
            vertical-align: middle;
            margin-bottom: 5px;
            font-weight: $font-weight-light;
        }
        label:before {
            content: "L";
            font-family: "telus";
            margin: 0px 10px 5px 2px;
            vertical-align: text-bottom;
            color: #9A9A9A;
            line-height: 16px;
            font-size: 12px;
        }
        label.warning:before {
            content: "J";
            color: $red-c8;
            font-size: 16px;
            margin: 0px 8px 0px 0px;
            line-height: 1;
        }
        label.success:before {
            content: "K";
            color: $green-66;
            font-size: 16px;
            margin: 0px 8px 0px 0px;
            line-height: 1;
        }
    }
}
