a {
  color: $purple-49;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a.telus-underline {
  text-decoration: underline !important;
}