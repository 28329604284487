// ==========================================================================
// Base – Variables
// ==========================================================================

// Main color definitions
$black: #000000;
$white: #FFFFFF;

// green
$green-66: #66CC00;
$green-40: #408000;
$green-57: #57A708;

// purple
$purple-49: #49166d;
$purple-8c: #8C68A6;

// gray
$gray-f5: #F5F6F7;
$gray-ae: #AEAEAE;
$gray-c4: #C4C4C4;
$gray-66: #666666;
$gray-33: #333333;
$gray-ce: #CECECE;

//other colors
$red-c8: #C81235;
$yellow-fe: #FEB811;
$brown-48: #482E06;

//Class definitions
.color-purple {
  color: $purple-49;
}

.color-green {
  color: $green-66;
}