.heading-large {
  font-family: $font-family-sans-serif;
  font-size: 37px;
}

.heading-medium {
  font-family: $font-family-sans-serif;
  font-size: 27px;
}

.heading,
.heading-normal {
  font-family: $font-family-sans-serif;
  font-size: 23px;
}

.heading-small {
  font-family: $font-family-sans-serif;
  font-size: 17px;
}