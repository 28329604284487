.body-21px {
  font-family: $font-family-sans-serif;
  font-size: 21px;
}

.body-17px {
  font-family: $font-family-sans-serif;
  font-size: 17px;
}

.body-14px {
  font-family: $font-family-sans-serif;
  font-size: 14px;
}