.telus-select-container:after {
  font-family: "telus";
  content: "x";
}

.telus-select-container {
  display: inline-block;
  font-size: 17px;
  position: relative;
}

.telus-select-container select {
  background-color: white;
  font-family: $font-family-sans-serif;
  font-size: 14px;
  line-height: 24px;
  padding: 0 12px;
  height: 44px;
  position: relative;
  top: 0;
  margin: 2px 0;
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-appearance: none;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
}
.telus-select-container select:hover {
  cursor: pointer;
}

.telus-select-container:after {
  position: absolute;
  right: 0.5em;
  top: 0.8em;
  pointer-events: none;
  font-size: 16px;
  color: $purple-8c;
}
.telus-select-container select {
  padding: 10px 5px 10px 10px;
  width: 100%;
  -moz-appearance: none;
  text-overflow: '';
}

.telus-select-container select::-ms-expand {
  display: none;
}

.telus-icon.color-white, .color-white.telus-select-container:after {
  color: $white;
}

.telus-select-container.color-light select {
  background-color: white;
  color: #666666;
  border-color: #e2e2e2;
}

.telus-select-container.color-light select {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
