.loading-spinner {
  width: 100px;
  height: 100px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 50%;
  margin: auto;

  .fallback {
    display: none;
  }
}
.no-smil .loading-spinner svg {
  display: none;
}
//fallback if SVG SMIL is not supported
.no-smil .loading-spinner .fallback {
  display: block;
  background-size: 50%;
  background-position: center center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}
.loading-spinner svg {
  @include animation(rotate, 1.8s, infinite, linear);
  height: 100%;
  width: 100%;
}
.loading-spinner circle {
  @include multiple-animation("dash 1.7s ease-in-out infinite 0s", "spinner-color 6s ease-in-out infinite -0.75s");
}
