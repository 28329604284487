@charset "UTF-8";


@mixin fontFace($family,$src,$style: normal,$weight: normal) {
  @font-face {
    font-family: $family;
    src:url("#{$src}.eot"); //IE9 Compatible
    src:url("#{$src}.eot?#iefix") format("embedded-opentype"), //IE8 below
      url("#{$src}.woff") format("woff"), // Standards
      url("#{$src}.ttf") format("truetype"), // Safari, Android, iOS
      url("#{$src}.svg##{$family}") format("svg"); // legacy iOS
    font-weight: $style;
    font-style: $weight;
  }
}

@include fontFace('telus','fonts/telus');

.telus-icon {
  font-family: "telus";
  font-size: 40px;
  font-style: normal;
  color: $green-66;
}

[data-icon]:before {
  font-family: "telus" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "telus" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-refresh-bold:before {
  content: "\61";
}
.icon-civiewer:before {
  content: "\62";
}
.icon-analytics:before {
  content: "\63";
}
.icon-known-errors:before {
  content: "\64";
}
.icon-sonar:before {
  content: "\65";
}
.icon-submit-action:before {
  content: "\66";
}
.icon-arrange:before {
  content: "\67";
}
.icon-checkmark:before {
  content: "\68";
}
.icon-dashboard:before {
  content: "\69";
}
.icon-ehealth:before {
  content: "\6a";
}
.icon-export:before {
  content: "\6b";
}
.icon-first-page:before {
  content: "\6c";
}
.icon-last-page:before {
  content: "\6d";
}
.icon-link-arrow:before {
  content: "\6e";
}
.icon-next-page:before {
  content: "\6f";
}
.icon-traffic-reporting:before {
  content: "\70";
}
.icon-service-status:before {
  content: "\71";
}
.icon-search:before {
  content: "\72";
}
.icon-route-analytics:before {
  content: "\73";
}
.icon-kinetic:before {
  content: "\74";
}
.icon-refresh:before {
  content: "\75";
}
.icon-previous-page:before {
  content: "\76";
}
.icon-close:before {
  content: "\77";
}
.icon-down-arrow:before {
  content: "\78";
}
.icon-user-admin:before {
  content: "\79";
}
.icon-profile:before {
  content: "\7a";
}
.icon-contact:before {
  content: "\41";
}
.icon-orientation:before {
  content: "\42";
}
.icon-exclamation:before {
  content: "\43";
}
.icon-reports:before {
  content: "\44";
}
.icon-minus:before {
  content: "\45";
}
.icon-plus:before {
  content: "\46";
}
.icon-ontario-logo:before {
  content: "\47";
}
.icon-warning:before {
  content: "\48";
}
.icon-performance:before {
  content: "\49";
}
.icon-docs-secure:before {
  content: "\4a";
}
.icon-success:before {
  content: "\4b";
}
.icon-bullet:before {
  content: "\4c";
}
.icon-chat-dots:before {
  content: "\4d";
}
.icon-padlock:before {
  content: "\e068";
}
.icon-arrow-right-circled:before {
  content: "\e095";
}
