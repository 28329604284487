.telus-button {
  font-family: $font-family-sans-serif;
  font-size: 19px;
  line-height: 19px;
  text-align: center;
  border-radius: 3px;
  display: inline-block;
  text-decoration: none;
  padding: 10px 20px 14px 20px;
}

.telus-button:hover {
  cursor: pointer;
}

.telus-button:hover, .telus-button:focus, .telus-button.state-selected {
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, $gray-ce), color-stop(90%, $gray-ce), color-stop(90%, $gray-ce), color-stop(100%, $gray-ce));
  background-image: -webkit-linear-gradient($gray-ce 0%, $gray-ce 90%, $gray-ce 90%, $gray-ce);
  background-image: -moz-linear-gradient($gray-ce 0%, $gray-ce 90%, $gray-ce 90%, $gray-ce);
  background-image: -o-linear-gradient($gray-ce 0%, $gray-ce 90%, $gray-ce 90%, $gray-ce);
  background-image: linear-gradient($gray-ce 0%, $gray-ce 90%, $gray-ce 90%, $gray-ce);
  background-color: $gray-ce;
  text-decoration: none;
}

.telus-button.color-purple {
  color: white;
  border: none;
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #8c68a6), color-stop(90%, #8c68a6), color-stop(90%, #49166d), color-stop(100%, #49166d));
  background-image: -webkit-linear-gradient(#8c68a6 0%, #8c68a6 90%, #49166d 90%, #49166d);
  background-image: -moz-linear-gradient(#8c68a6 0%, #8c68a6 90%, #49166d 90%, #49166d);
  background-image: -o-linear-gradient(#8c68a6 0%, #8c68a6 90%, #49166d 90%, #49166d);
  background-image: linear-gradient(#8c68a6 0%, #8c68a6 90%, #49166d 90%, #49166d);
  background-color: #8c68a6;
}
.telus-button.color-purple:hover, .telus-button.color-purple:focus, .telus-button.color-purple.state-selected {
  color: white;
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #49166d), color-stop(90%, #49166d), color-stop(90%, #49166d), color-stop(100%, #49166d));
  background-image: -webkit-linear-gradient(#49166d 0%, #49166d 90%, #49166d 90%, #49166d);
  background-image: -moz-linear-gradient(#49166d 0%, #49166d 90%, #49166d 90%, #49166d);
  background-image: -o-linear-gradient(#49166d 0%, #49166d 90%, #49166d 90%, #49166d);
  background-image: linear-gradient(#49166d 0%, #49166d 90%, #49166d 90%, #49166d);
  background-color: #49166d;
}
.telus-button.color-green {
  color: white;
  border: none;
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #57a708), color-stop(90%, #57a708), color-stop(90%, #408000), color-stop(100%, #408000));
  background-image: -webkit-linear-gradient(#57a708 0%, #57a708 90%, #408000 90%, #408000);
  background-image: -moz-linear-gradient(#57a708 0%, #57a708 90%, #408000 90%, #408000);
  background-image: -o-linear-gradient(#57a708 0%, #57a708 90%, #408000 90%, #408000);
  background-image: linear-gradient(#57a708 0%, #57a708 90%, #408000 90%, #408000);
  background-color: #57a708;
}
.telus-button.color-green:hover, .telus-button.color-green:focus, .telus-button.color-green.state-selected {
  color: white;
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #408000), color-stop(90%, #408000), color-stop(90%, #408000), color-stop(100%, #408000));
  background-image: -webkit-linear-gradient(#408000 0%, #408000 90%, #408000 90%, #408000);
  background-image: -moz-linear-gradient(#408000 0%, #408000 90%, #408000 90%, #408000);
  background-image: -o-linear-gradient(#408000 0%, #408000 90%, #408000 90%, #408000);
  background-image: linear-gradient(#408000 0%, #408000 90%, #408000 90%, #408000);
  background-color: #408000;
}
.telus-button.color-white {
  color: #8350a7;
  border: 1px solid $gray-ce;
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #ffffff), color-stop(90%, #ffffff), color-stop(90%, $gray-ce), color-stop(100%, $gray-ce));
  background-image: -webkit-linear-gradient(#ffffff 0%, #ffffff 90%, $gray-ce 90%, $gray-ce);
  background-image: -moz-linear-gradient(#ffffff 0%, #ffffff 90%, $gray-ce 90%, $gray-ce);
  background-image: -o-linear-gradient(#ffffff 0%, #ffffff 90%, $gray-ce 90%, $gray-ce);
  background-image: linear-gradient(#ffffff 0%, #ffffff 90%, $gray-ce 90%, $gray-ce);
  background-color: white;
}
.telus-button.color-white:hover, .telus-button.color-white:focus, .telus-button.color-white.state-selected {
  color: #8c68a6;
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, $gray-ce), color-stop(90%, $gray-ce), color-stop(90%, $gray-ce), color-stop(100%, $gray-ce));
  background-image: -webkit-linear-gradient($gray-ce 0%, $gray-ce 90%, $gray-ce 90%, $gray-ce);
  background-image: -moz-linear-gradient($gray-ce 0%, $gray-ce 90%, $gray-ce 90%, $gray-ce);
  background-image: -o-linear-gradient($gray-ce 0%, $gray-ce 90%, $gray-ce 90%, $gray-ce);
  background-image: linear-gradient($gray-ce 0%, $gray-ce 90%, $gray-ce 90%, $gray-ce);
  background-color: $gray-ce;
}

.telus-button-group {
  overflow: auto;
}
.telus-button-group .telus-button {
  float: left;
  border-radius: 0;
}
.telus-button-group .telus-button:first-of-type {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.telus-button-group .telus-button:last-of-type {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.telus-button.disabled,
.telus-button.disabled:hover,
.telus-button.disabled:active,
.telus-button.disabled:focus {
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, $gray-ce), color-stop(90%, $gray-ce), color-stop(90%, $gray-ae), color-stop(100%, $gray-ae));
  background-image: -webkit-linear-gradient($gray-ce 0%, $gray-ce 90%, $gray-ae 90%, $gray-ae);
  background-image: -moz-linear-gradient($gray-ce 0%, $gray-ce 90%, $gray-ae 90%, $gray-ae);
  background-image: -o-linear-gradient($gray-ce 0%, $gray-ce 90%, $gray-ae 90%, $gray-ae);
  background-image: linear-gradient($gray-ce 0%, $gray-ce 90%, $gray-ae 90%, $gray-ae);
  background-color: $gray-ce;
  text-decoration: none;
  cursor: not-allowed;
}


.telus-button.state-loading,
.telus-button.state-loading:hover,
.telus-button.color-green.state-loading,
.telus-button.color-green.state-loading:hover {
  color: white;
  position: relative;
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #cecece), color-stop(90%, #cecece), color-stop(90%, #a9a9a9), color-stop(100%, #a9a9a9));
  background-image: -webkit-linear-gradient(#cecece 0%, #cecece 90%, #a9a9a9 90%, #a9a9a9);
  background-image: -moz-linear-gradient(#cecece 0%, #cecece 90%, #a9a9a9 90%, #a9a9a9);
  background-image: -o-linear-gradient(#cecece 0%, #cecece 90%, #a9a9a9 90%, #a9a9a9);
  background-image: linear-gradient(#cecece 0%, #cecece 90%, #a9a9a9 90%, #a9a9a9);
  background-color: #cecece;
}
.telus-button.state-loading:after,
.telus-button.state-loading:hover:after,
.telus-button.color-green.state-loading:after,
.telus-button.color-green.state-loading:hover:after {
  -webkit-animation-name: progress;
  -moz-animation-name: progress;
  animation-name: progress;
  -webkit-animation-duration: 2s;
  -moz-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  position: absolute;
  display: inline-block;
  content: "";
  bottom: 0px;
  left: 0;
  height: 4px;
  z-index: 1;
  width: 100%;
  background: #888;
  @include transitions('width 3s');
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
