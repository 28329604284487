.telus-nav {
    background-color: white;
    font-family: $font-family-sans-serif;
    border-bottom: lighten($gray-c4, 16%) 1px solid;
    @media #{$xlarge-up} {
        padding: 15px 0px;
    }
    @media #{$large-only} {
        padding: 14px 0px;
    }
    @media #{$medium-only} {
        padding: 13px 0px;
    }
    @media #{$small-only} {
        padding: 10px 0px;
    }

    div.logo {
        vertical-align: -webkit-baseline-middle;
        vertical-align: middle;

        div {
            width: 150px;
        }
        div:before {
            padding-top: 0px;
            margin-top: 0px;
            @media #{$xlarge-up} {
                font-size: rem-calc(150);
                line-height: 55px;
            }
            @media #{$large-only} {
                font-size: rem-calc(120);
                line-height: 45px;
            }
            @media #{$medium-only} {
                font-size: rem-calc(100);
                line-height: 55px;
            }
            @media #{$small-only} {
                font-size: rem-calc(90);
                line-height: 55px;
            }
        }
    }
    a {
        float: right;
        display: inline-block;
        vertical-align: text-bottom;
        @media #{$xlarge-up} {
            font-size: rem-calc(18);
            padding: 28px 15px 10px 15px;
        }
        @media #{$large-only} {
            font-size: rem-calc(16);
            padding: 24px 13px 5px 13px;
        }
        @media #{$medium-only} {
            font-size: rem-calc(15);
            padding: 28px 10px 10px 10px;
        }
        @media #{$small-only} {
            font-size: rem-calc(13);
            padding: 28px 8px 0px 8px;
        }
    }

}
